import React from 'react';
import {
	Section,
	Container,
	Content,
	Image,
	Wrapper,
} from './FeaturesSectionElements';

const Features = ({ content }) => {
	return (
		<>
			<Section>
				<Container>
					<Content>
						<h2>{content.title}</h2>
						<p>{content.excerpt}</p>
					</Content>
					<Wrapper>
						{content.resources.map((images) => (
							<Image key={images.id} src={images.logo} alt={images.alt} />
						))}
					</Wrapper>
				</Container>
			</Section>
		</>
	);
};

export default Features;
