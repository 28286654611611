import styled from 'styled-components';

export const Container = styled.div`
	background: '#fff';
	min-height: 25vh;
	padding: 2rem 2rem;
	position: relative;
	overflow: auto;

	@media screen and (max-width: 120vh) {
		height: max-content;
		padding-top: 50px;
		padding-bottom: 50px;
	}
`;

export const Wrapper = styled.div`
	width: 100%;
	max-width: 65vw;
	margin: 0 auto;
`;

export const Content = styled.div`
	flex: 0 0 50%;
	color: '#010606';

	@media screen and (max-width: 768px) {
		flex: 0 0 100%;
		max-width: 100%;
	}

	h2 {
		margin-bottom: 2rem;
		font-size: 2rem;
	}

	p {
		margin-bottom: 1rem;
		line-height: 1.5;
	}

	ul {
		list-style-position: inside;
		margin-bottom: 1rem;
	}

	li {
		margin-bottom: 10px;
	}
`;
