import React from 'react';
import PageNav from '../../components/PageNav';
import HeroImageSection from '../../components/HeroImageSection';
import HubEmbed from '../../components/HubEmbed/HubEmbed'
import { contactPageData } from './Data';
import { Helmet } from 'react-helmet';

const Contact = () => {
	return (
		<>
			<Helmet>
				<title>Plotlogic - Contact Us</title>
				<meta name='description' content='' />
			</Helmet>
			<PageNav content={contactPageData} />
			<HeroImageSection content={contactPageData} />
			<HubEmbed 
				title="Contact our team to find out how we can work together to enhance your mine's performance and reduce environmental impact."
				embedId="1Hway2pIVQWi-APZX8kNxIgbwero"
			/>			
		</>
	);
};

export default Contact;
