import HeroImage from '../../images/hero-image-9.png';

export const LocationsPageData = {
	page: 'Locations',
	heroHeader: 'Locations',
	image: HeroImage,
	alt: 'Hero Banner',
};

export const LocationMarkers = [
	{
		name: 'Bowen Hills',
		coordinates: [153.04, -27.44174],
	},
	{
		name: 'Johannesburg',
		coordinates: [28.0668, -26.0668],
	},
	{
		name: 'Belo Horizonte',
		coordinates: [-44.0340901, -19.9027026],
	},
	{
		name: 'Groote Eylandt',
		coordinates: [136.5933, -13.97],
	},
];
