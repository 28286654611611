import HeroImage from '../../images/hero-image-9.png';
import AndrewJob from '../../images/andrew-job.png';
import GregQuill from '../../images/greg-quill.png';
import GaryPattemore from '../../images/gary-pattermore.png';
import MarinaAuad from '../../images/marina-auad.png';
import LorraineElsmore from '../../images/lorraine-elsmore.png';
import JaredCox from '../../images/jared-cox.png';
import RobertFisher from '../../images/robert-fisher.png';
import MickEdgar from '../../images/mick-edgar.png';
import JustinYoung from '../../images/justin-young.png';
import AndrewJessop from '../../images/andrew-jessop.png';
import KathrynJob from '../../images/kathryn-job.png';
import HannahRose from '../../images/hannah-rose.png';
import DelennPalmer from '../../images/delenn-palmer.png';
import JamesTroeth from '../../images/james-troeth.png';
import MartinKnoetze from '../../images/martin-knoetze.png';
import JarrodBowles from '../../images/jarrod-bowles.png';

import MatthewGarlan from '../../images/matthew-garlan.png';
import AlexYue from '../../images/alex-yue.png';
import TySchober from '../../images/ty-schober.png';
import JoelFenwick from '../../images/joel-fenwick.png';
import PaulErnst from '../../images/paul-ernst.png';
import BobVassie from '../../images/bob-vassie.png';
import SamSmith from '../../images/sam-smith.png';
import AliTamaseb from '../../images/ali-tamaseb.png';
import SamanFarid from '../../images/saman-farid.png';
import AdamReyneke from '../../images/adam-reyneke.png';
import AndreasRamm from '../../images/andreas-ramm.png';
import HughArmitage from '../../images/hugh-armitage.png';
import MartyKeetels from '../../images/marty-keetels.png';
import RebeccaWilkie from '../../images/rebecca-wilkie.png';
import VijayChary from '../../images/vijay-chary.png';
import RichardMurphy from '../../images/richard-murphy.png';
import Investor8VC from '../../images/investor-8vc.png';
import InvestorBaidu from '../../images/investor-baidu.png';
import InvestorDCVC from '../../images/investor-dcvc.png';
import InvestorGridsCapital from '../../images/investor-grids-capital.png';
import InvestorInnovationEndeavours from '../../images/investor-innovation-endeavours.jpg';
import InvestorBentleySystems from '../../images/investor-bentley-systems.jpg';
import PartnerMRIWA from '../../images/partner-mriwa.png';
import PartnerUQ from '../../images/partner-uq.png';
import PartnerACARP from '../../images/partner-acarp.jpg';
import ClientBHP from '../../images/client-bhp.png';
import ClientAngloAmerican from '../../images/client-anglo-american.png';
import ClientAngloGoldAshanti from '../../images/client-anglogold-ashanti.png';
import ClientCiticPacific from '../../images/client-citic-pacific.png';
import ClientPeabodyEnergy from '../../images/client-peabody-energy.png';
import ClientSeverstal from '../../images/client-severstal.png';
import ClientSouth32 from '../../images/client-south32.png';
import ClientThiess from '../../images/client-thiess.png';
import ClientSimec from '../../images/client-simec.png';
import ClientGlencore from '../../images/client-glencore.png';
import ClientEQResources from '../../images/client-eq-resources.png';
import ClientEvolutionMining from '../../images/client-evolution-mining.png';
import ClientAeris from '../../images/client-aeris.png';
import ClientACAP from '../../images/client-acap.png';
import ClientSandvik from '../../images/client-sandvik.png';

export const AboutPageData = {
	page: 'About',
	heroHeader: 'About Us',
	image: HeroImage,
	alt: 'Hero Banner',
};

export const IntroData = {
	heading: 'A brief overview of our company',
	description: [
		'Plotlogic’s goal is to make precision mining a reality for the entire industry. Andrew Job founded Plotlogic in 2018 while completing his PhD at the University of Queensland. Andrew combined his research with his extensive experience as a mining manager to develop a solution for autonomous material characterisation in mining.',
		'OreSense®, Plotlogic’s first commercial product, combines LiDAR and hyperspectral imaging technology with advanced machine learning algorithms to deliver highly accurate ore characterisation. ',
		"Plotlogic's OreSense® technology has been extensively developed and tested, with partnerships in place with BHP and other major mining companies.",
	],
};

export const PeopleData = [
	{
		id: 'pl-1',
		icon: AndrewJob,
		name: 'Andrew Job',
		title: 'Founder & CEO',
		bio: "Andrew is a mining engineer with over 10 years’ experience in the mining industry. Andrew combined his PhD studies with his in-depth mining operations experience and is dedicated to leading the mining industry's transition to a high precision, high tech future.",
		linkedin: '//www.linkedin.com/in/andrewtjob/',
	},
	{
		id: 'pl-2',
		icon: GregQuill,
		name: 'Greg Quill',
		title: 'Head of People & Culture',
		bio: 'Greg has over 15 years of management experience in the mining sector. Greg applies his understanding of the mining sector and management skills to further develop Plotlogic’s people and processes. Greg has worked around the globe with clients such as Glencore, Anglo American, Rio Tinto, Downer EDI, Lihir Gold, Whitehaven and NCIG.',
		linkedin: '//www.linkedin.com/in/greg-quill-908323161/',
	},
	{
		id: 'pl-3',
		icon: JustinYoung,
		name: 'Justin Young',
		title: 'Head of Product',
		bio: 'Justin started his career with a Bachelor of Engineering, Electrical and Computer Systems at Monash University. Justin facilitates the development of Plotlogic’s product pipeline and is passionate about creating and integrating technologies that add value to mining operations. Justin has worked for large companies such as Rio Tinto and Boeing and uses his client side and technical experience to develop dependable solutions.',
		linkedin: '//www.linkedin.com/in/justingyoung/ ',
	},
	{
		id: 'pl-4',
		icon: MartyKeetels,
		name: 'Marty Keetels',
		title: 'Vice President - Commercial',
		bio: 'Marty is a passionate business professional, experienced across sales, marketing and most importantly building successful customer relationships. Marty is qualified and experiences in Law, Arts, International Relations and Politics, Management, Innovation, Marketing and Management. At Plotlogic Marty leads his team to deliver successful customer relationships and business growth.',
		linkedin: '//www.linkedin.com/in/martinkeetels/',
	},
	{
		id: 'pl-6',
		icon: LorraineElsmore,
		name: 'Lorraine Elsmore',
		title: 'Specialist Outbound Lead Generation',
		bio: 'Lorraine has worked in the mining industry for over 20 years, developing business and marketing processes. Lorraine is dedicated to fostering relationships that enable the natural resources industry to be profitable, sustainable and safe.',
		linkedin: '//www.linkedin.com/in/lorraine-elsmore/',
	},
	{
		id: 'pl-7',
		icon: AndreasRamm,
		name: 'Andreas Ramm',
		title: 'Vice President - Operations',
		bio: 'Andreas has over 20 years’ experience in software development, beginning with studies in mathematical and technical software engineering, followed by completion of his Master of Business Administration and Management later in his career. Andreas has worked across a wide range of industries including robotics and industrial machine automation and combines his technical knowledge and management skills to lead his team in the completion of successful software development projects.',
		linkedin: '//www.linkedin.com/in/andreas-ramm/',
	},
	{
		id: 'pl-8',
		icon: MarinaAuad,
		name: 'Marina Auad',
		title: 'Head of Brazilian Office',
		bio: 'Marina leads our Brazil office and was one of the first Plotlogic employees. Marina is a Geologist with a diverse range of experience in the classification of minerals using a variety of techniques. Marina uses her experience to help develop and educate mining companies in the use of the OreSense® for efficient mining.',
		linkedin: '//www.linkedin.com/in/marinaauad/',
	},
	{
		id: 'pl-9',
		icon: AndrewJessop,
		name: 'Andrew Jessop',
		title: 'Vice President - Engineering',
		bio: 'Andrew studied a Bachelor of Computer Systems Engineering and a Master of Business and his extensive skills and experience have led him to his role as the Platform/Data AI Software Engineering Manager. Andrew is passionate about the integration of technology with everyday tasks, making mundane operations more efficient and effective. Andrew’s specialty is his ability to relay technical information to a variety of non-technical stakeholders.',
		linkedin: '//www.linkedin.com/in/andrewdjessop/',
	},
	{
		id: 'pl-10',
		icon: JaredCox,
		name: 'Jared Cox',
		title: 'Manager Analytics & Reporting',
		bio: 'Jared holds a Bachelor of Engineering, Aerospace Avionics and originally worked as a software engineer for a surveying company in power network and subsea applications. Jared then spent 10 years in data acquisition/analysis and software development in supercars racing. At Plotlogic Jared leads the data processing algorithms and client facing web platform projects for the software team.',
		linkedin: '//www.linkedin.com/in/jared-cox-5a6176102/',
	},
	{
		id: 'pl-11',
		icon: HughArmitage,
		name: 'Hugh Armitage',
		title: 'Product Designer',
		bio: 'Hugh is highly experienced working in R&D companies and bringing innovative products to life. Hugh is passionate about making a positive social impact and uses this passion to educate Plotlogic customers on the use of hyperspectral imaging to improve mine processes. Hugh has his degree in mechatronics, robotics and automation engineering and Masters in Engineering, Asset Management.',
		linkedin: '//www.linkedin.com/in/hugh-armitage/',
	},
	{
		id: 'pl-12',
		icon: JamesTroeth,
		name: 'James Troeth',
		title: 'Sr. Mechatronics Engineer',
		bio: 'James was Plotlogic’s first Mechatronics & Robotics Engineer and has a proven track record designing electro-mechanical systems and machines. James worked for a variety of R&D companies before joining Plotlogic and continues to build a strong career in mechatronics and robotics here at Plotlogic. James’ main focus is writing the drivers that interface with the hardware.',
		linkedin: '//www.linkedin.com/in/james-troeth/',
	},
	{
		id: 'pl-13',
		icon: MatthewGarlan,
		name: 'Matthew Garlan',
		title: 'Sr. Mechanical Design Engineer',
		bio: 'Matt completed his Bachelor of Mechanical Engineering in 2016 and spent the first 8 years of his career working in the automotive industry.  Matt uses his extensive design experience to bring the different OreSense® configurations to life.  Matt is an asset to the hardware team and is rapidly  developing his skills in the mining technology industry, developing tailored designs to bring the OreSense® system and mine site infrastructure together.',
		linkedin: '//www.linkedin.com/in/matthew-garlan-105b089b/',
	},
	{
		id: 'pl-14',
		icon: GaryPattemore,
		name: 'Dr. Gary Pattemore',
		title: 'Manager Research',
		bio: 'Gary is a Spectral Geologist with a wide range of experience in research, government and industry. Gary has an engineering degree and a PhD in Geology. Gary works alongside our software and hardware teams developing and integrating advanced algorithms to enable classification of a diverse range of materials.',
		linkedin: '//www.linkedin.com/in/gary-pattemore-59319a207/',
	},
	{
		id: 'pl-15',
		icon: MickEdgar,
		name: 'Dr. Mick Edgar',
		bio: 'Mick has a PhD in Physics and is well respected in his field, with over 30 years’ experience Mick is highly experienced in the design, construction and commissioning of advanced Radio Frequency (RF) and optical instrumentation within several fields of experimental physics and engineering, for a wide variety of applications. Mick brings a wealth of knowledge to Plotlogic and drives research and development initiatives. Mick is experienced in software development, cryptography, numerical methods, real-time control of a wide variety of equipment, image processing, thin film fabrication, cryogenics, vacuum techniques, electronics and high-power electrical systems.',
		title: 'Sr. Physicist',
	},
	{
		id: 'pl-16',
		icon: KathrynJob,
		name: 'Kathryn Job',
		title: 'Product Designer',
		bio: 'Kathryn is a Geologist with experience working in the natural resources industry and an in depth understanding of geological operations on mine sites. Kathryn has a degree in both Geophysics and Geology/Earth Science and uses her knowledge to determine the best method to deliver value for our clients.',
		linkedin: '//www.linkedin.com/in/kathryn-job-88ba88b4/',
	},
	{
		id: 'pl-17',
		icon: DelennPalmer,
		name: 'Delenn Palmer',
		title: 'Software Engineer',
		bio: 'Del studied a double degree in Business (Accounting) and Computer and Software Engineering at QUT. Del started her career drafting electrical systems for construction projects, then became a Research and Development (R&D) Tax Analyst at Deloitte. Working at Deloitte, Del discovered her passion for R&D and being at the forefront of leading technology, which led her to working at Plotlogic. Del is integral to the Platforms division, in which they develop the user interface to allow operators to control the OreSense® system, as well as develop cloud infrastructure (i.e. database structures, data ingest functionality) to store data retrieved.',
		linkedin: '//www.linkedin.com/in/delenn-palmer-83b533113/',
	},
	{
		id: 'pl-20',
		icon: HannahRose,
		name: 'Hannah Rose',
		title: 'Sr. Marketing Specialist',
		bio: 'Hannah grew up in Mt Isa a mining town with one of the most productive single mines in world history, based on combined production of lead, silver, copper and zinc. Hannah moved on to study a Bachelor of Business Marketing at CQU and has worked in a variety of roles. At Plotlogic Hannah focuses on all things marketing and delivering information to our clients to assist them when purchasing our products.',
		linkedin: '//www.linkedin.com/in/hannahmagdics-mp/',
	},
	{
		id: 'pl-21',
		icon: RobertFisher,
		name: 'Robert Fisher',
		title: 'Mechatronics Engineer',
		bio: 'Robert completed his studies at UQ in 2007, graduating with a Bachelor of Mechatronics Engineering. Robert is an experienced and qualified mechatronics / robotics engineer with extensive experience and success working as part of an engineering team to develop and deliver design engineering solutions. Robert now works as part of the Plotlogic data acquisition team that develops the core functionality of the OreSense® system and focuses on solving problems and enhancing the system to meet new customer requirements.',
		linkedin: '//www.linkedin.com/in/robertfisher84/',
	},
	{
		id: 'pl-22',
		icon: AdamReyneke,
		name: 'Adam Reyneke',
		bio: 'Adam is Plotlogic’s Production Coordinator and works as part of the hardware team ensuring efficient and effective production of the OreSense system. Adam has a dual trade qualification (electrical and mechanical) and is very experienced in maintenance, product development and logistical support.',
		title: 'Production Coordinator',
	},
	{
		id: 'pl-23',
		icon: MartinKnoetze,
		name: 'Martin Knoetze',
		title: ' Sr. Mechatronics Engineer',
		bio: 'Martin gained his Bachelor of Mechatronics Engineering qualification at QUT while also completing his engineering internship at CSIRO. Martin then worked for a company specialising in high quality data capture for a variety of industries, before starting with Plotlogic. At Plotlogic, Martin builds and tests software to expand the offerings of the OreSense® system.',
		linkedin: '//www.linkedin.com/in/martin-knoetze-22845619a/',
	},
	{
		id: 'pl-24',
		icon: JarrodBowles,
		name: 'Jarrod Bowles',
		title: 'Mechanical Engineer',
		bio: 'Jarrod is one of Plotlogic’s product engineers and an integral part of the hardware team. Jarrod studied a Bachelor of Automotive engineering at RMIT University and worked in the automotive industry for 10 years prior to joining Plotlogic. Jarrod plays an important role in product manufacturing including mechanical assembly and IT system design and maintenance.',
		linkedin: '//www.linkedin.com/in/jarrod-bowles-1363796b/',
	},
	{
		id: 'pl-25',
		icon: TySchober,
		name: 'Ty Schober',
		title: 'Sr. Electronics Engineer',
		bio: 'Ty studied a Bachelor of Engineering, Electronic and Computer Engineering at Griffith University and has worked exclusively in start-ups and R&D companies since leaving university. Ty manages everything hardware related at Plotlogic, ensuring the production and logistics of the OreSense® systems are completed efficiently and effectively. Ty has a diverse skill-set across hardware, firmware, software, and robotics and a deep technical knowledge of WiFi.',
		linkedin: '//www.linkedin.com/in/ty-schober-3793a943/',
	},
	{
		id: 'pl-26',
		icon: RebeccaWilkie,
		name: 'Rebecca Wilkie',
		title: 'Software Engineer',
		bio: 'Rebecca studied a Bachelor of Information Technology and Electronic Engineering at the Queensland University of Technology and has worked in machine automation for over 10 years. Rebecca brings her wealth of software engineering experience and to design, write, test, and debug code to ensure smooth operation of the system for our clients.',
		linkedin: '//www.linkedin.com/in/rebecca-wilkie/',
	},
	{
		id: 'pl-27',
		icon: JoelFenwick,
		name: 'Dr. Joel Fenwick',
		title: 'Software Engineer',
		bio: 'Joel has a PhD in Philosophy and has completed both computer science and math degrees. Joel uses his high-level qualifications and experience to work on research and development projects and educate others. Joel enjoys solving problems and working with a team creating automated high performance research systems.',
		linkedin: '//www.linkedin.com/in/joel-fenwick-85bb4b1b3/',
	},
	{
		id: 'pl-30',
		icon: VijayChary,
		name: 'Vijay Chary',
		bio: "Vijay is Plotlogic’s IT Systems Administrator and brings almost 20 years' experience in a diverse range of platforms. Vijay has a Master’s in Information Technology from USQ and dedicates his time to improving accessibility, data storage and security, connectivity, and infrastructure support to ensure our teams can work efficiently and effectively.",
		title: 'Sr. IT Administrator',
	},
	{
		id: 'pl-31',
		icon: AlexYue,
		name: 'Alex Yue',
		title: 'Software Engineer',
		bio: 'Alex is a new graduate with a Bachelor of Engineering, Mechatronics and has joined the Plotlogic team to contribute his knowledge and skills gained thus far, whilst also learning from the experienced team members at Plotlogic.',
		linkedin: '//www.linkedin.com/in/alexander-en-hao-yue-74926b146/ ',
	},
];

export const AdvisorData = [
	{
		id: 'ad-1',
		icon: AndrewJob,
		name: 'Andrew Job',
		title: 'Founder & CEO',
		about:
			'Andrew founded Plotlogic in 2018, combining his mining experience and passion for the application of hyperspectral imaging and machine learning techniques for real-time ore characterisation. Andrew is the driving force behind Plotlogic and encourages his team to think creatively when developing new solutions.',
	},
	{
		id: 'ad-2',
		icon: RichardMurphy,
		name: 'Dr. Richard Murphy',
		title: 'Board / Advisory',
		about:
			'Richard is a senior spectral geologist with over 20 years experience working with the mining industry. Richard is well respected in his field and has completed a diverse range of research projects involving hyperspectral imaging and data. Richard is a great source of technical advice for the Plotlogic team.',
	},
	{
		id: 'ad-3',
		icon: PaulErnst,
		name: 'Paul Ernst',
		title: 'Board / Advisory',
		about:
			'Paul is an experienced mining executive, with over 30 years experience in the natural resources industry. Paul gained his experience both nationally and internationally in different commodity groups including coal, diamonds and gold.',
	},
	{
		id: 'ad-4',
		icon: BobVassie,
		name: 'Bob Vassie',
		title: 'Board / Advisory',
		about:
			"Bob has worked in the mining industry for over 35 years and is well respected among mining executives. Bob has worked in management roles for companies including Rio Tinto, Ivanhoe Australia and St. Barbara. Bob contributes his in-depth operations experience and strategic knowledge in guiding Plotlogic's board.",
	},
	{
		id: 'ad-5',
		icon: SamSmith,
		name: 'Sam Smith-Eppsteiner',
		title: 'Board / Advisory',
		about:
			'Sam is a partner at Innovation Endeavors, a leading Silicon Valley based venture capital firm that invests in visionary founders leveraging technology to drive human, planetary, and financial impact. Sam focuses on innovation in industrial sectors and previously worked for Bain & Company, Nuro and Owkin. Sam holds an MBA from the Stanford Graduate School of Business and a BA in Political Science from Stanford University, where she graduated Phi Beta Kappa.',
	},
	{
		id: 'ad-6',
		icon: SamanFarid,
		name: 'Saman Farid',
		title: 'Board / Advisory',
		about:
			'Saman Farid is the US head of Baidu Ventures, an artificial intelligence focused venture capital firm. Saman is passionate about business and started his own company at the age of 14. Saman is highly regarded in the artificial intelligence and tech industries, and engages with new technologies daily.',
	},
	{
		id: 'ad-7',
		icon: AliTamaseb,
		name: 'Ali Tamaseb',
		title: 'Board / Advisory',
		about:
			'Ali Tamaseb is an investor at DCVC, an early-stage deep-tech venture capital firm. Ali is extremely experienced with start-up businesses and is well respected in the industry. Ali recently published a best-selling book and enjoys sharing his knowledge.',
	},
];

export const InvestorsData = {
	title: 'Our Investors',
	excerpt:
		'Plotlogic are proudly supported by a team of investors ready revolutionize the mining industry',
	resources: [
		{
			id: 'in-1',
			investorName: '8VC',
			logo: Investor8VC,
			alt: '8VC',
		},
		{
			id: 'in-2',
			investorName: 'Baidu',
			logo: InvestorBaidu,
			alt: 'Baidu',
		},
		{
			id: 'in-3',
			investorName: 'Data Collective',
			logo: InvestorDCVC,
			alt: 'Data Collective',
		},
		{
			id: 'in-4',
			investorName: 'Grids Capital',
			logo: InvestorGridsCapital,
			alt: 'Grids Capital',
		},
		{
			id: 'in-5',
			investorName: 'Innovation Endeavours',
			logo: InvestorInnovationEndeavours,
			alt: 'Innovation Endeavours',
		},
		{
			id: 'in-6',
			investorName: 'Bentley Systems',
			logo: InvestorBentleySystems,
			alt: 'Bentley Systems',
		},
	],
};

export const ExperienceData = {
	title: 'Our Experience',
	excerpt:
		'The Plotlogic Team has partnered and worked with a range of clients in the mining industry',
	resources: [
		{
			id: 'ex-1',
			client: 'BHP',
			logo: ClientBHP,
			alt: 'BHP',
		},
		{
			id: 'ex-2',
			client: 'Severstal',
			logo: ClientSeverstal,
			alt: 'Severstal',
		},
		{
			id: 'ex-3',
			client: 'Anglo American',
			logo: ClientAngloAmerican,
			alt: 'Anglo American',
		},
		{
			id: 'ex-4',
			client: 'Anglo Gold Ashanti',
			logo: ClientAngloGoldAshanti,
			alt: 'Anglo Gold Ashanti',
		},
		{
			id: 'ex-5',
			client: 'Citic Pacific',
			logo: ClientCiticPacific,
			alt: 'Citic Pacific',
		},
		{
			id: 'ex-6',
			client: 'Peabody Energy',
			logo: ClientPeabodyEnergy,
			alt: 'Peabody Energy',
		},
		{
			id: 'ex-7',
			client: 'South32',
			logo: ClientSouth32,
			alt: 'South32',
		},
		{
			id: 'ex-8',
			client: 'ACARP',
			logo: PartnerACARP,
			alt: 'ACARP',
		},
		{
			id: 'ex-9',
			client: 'UQ',
			logo: PartnerUQ,
			alt: 'UQ',
		},
		{
			id: 'ex-10',
			client: 'MRIWA',
			logo: PartnerMRIWA,
			alt: 'MRIWA',
		},
		{
			id: 'ex-11',
			client: 'Thiess',
			logo: ClientThiess,
			alt: 'Thiess',
		},
		{
			id: 'ex-11',
			client: 'Simec',
			logo: ClientSimec,
			alt: 'Simec',
		},
		{
			id: 'ex-13',
			client: 'Glencore',
			logo: ClientGlencore,
			alt: 'Glencore',
		},
		{
			id: 'ex-14',
			client: 'EQ Resources',
			logo: ClientEQResources,
			alt: 'EQ Resources',
		},
		{
			id: 'ex-15',
			client: 'Evolution Mining',
			logo: ClientEvolutionMining,
			alt: 'Evolution Mining',
		},
		{
			id: 'ex-16',
			client: 'Aeris',
			logo: ClientAeris,
			alt: 'Aeris',
		},
		{
			id: 'ex-17',
			client: 'ACAP',
			logo: ClientACAP,
			alt: 'ACAP',
		},
		{
			id: 'ex-18',
			client: 'Sandvik',
			logo: ClientSandvik,
			alt: 'Sandvik',
		},
	],
};
