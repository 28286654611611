import styled from 'styled-components';

export const Container = styled.div`
	background: '#fff';
	padding: 5rem 4rem;
	position: relative;
`;

export const Wrapper = styled.div`
	max-width: 100%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	grid-gap: 32px;
	padding: 0 30px;

	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
		padding: 0 20px;
	}

	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr 1fr;
		padding: 0 20px;
	}

	@media screen and (max-width: 800px) {
		grid-template-columns: 1fr;
		padding: 0 20px;
	}
`;

export const Content = styled.div`
	flex: 0 0 50%;
	text-align: center;

	@media screen and (max-width: 768px) {
		flex: 0 0 100%;
		max-width: 100%;
	}

	h2 {
		margin-bottom: 2rem;
		font-size: 2rem;
	}

	p {
		margin-bottom: 1rem;
		line-height: 1.5;
	}
`;

export const Image = styled.img`
	width: 75%;
	height: 75%;
	object-fit: contain;
	align-items: center;
`;
