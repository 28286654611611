import React, { useState } from 'react';
import { ButtonR } from '../ButtonElement';
import {
	InfoContainer,
	InfoWrapper,
	InfoRow,
	Column1,
	Column2,
	TextWrapper,
	TopLine,
	Heading,
	Subtitle,
	BtnWrap,
	ImgWrap,
	Img,
	ArrowForward,
	ArrowRight,
} from './InfoElements';

const InfoSection = ({
	lightBg,
	id,
	imgStart,
	topLine,
	topLineColor,
	lightText,
	headline,
	darkText,
	descriptionOne,
	descriptionTwo,
	buttonLabel,
	img,
	alt,
	to,
	primary,
	dark,
	dark2,
}) => {
	const [hover, setHover] = useState(false);

	const onHover = () => {
		setHover(!hover);
	};

	return (
		<>
			<InfoContainer lightBg={lightBg} id={id}>
				<InfoWrapper>
					<InfoRow imgStart={imgStart}>
						<Column1>
							<TextWrapper>
								<TopLine topLineColor={topLineColor}>{topLine}</TopLine>
								<Heading lightText={lightText}>{headline}</Heading>
								<Subtitle darkText={darkText}>{descriptionOne}</Subtitle>
								<Subtitle darkText={darkText}>{descriptionTwo}</Subtitle>
								<BtnWrap>
									<ButtonR
										to={to}
										onMouseEnter={onHover}
										onMouseLeave={onHover}
										smooth={true.toString()}
										duration={500}
										spy={true.toString()}
										exact='true'
										offset={-80}
										primary={primary ? 1 : 0}
										dark={dark ? 1 : 0}
										dark2={dark2 ? 1 : 0}
									>
										{buttonLabel}
										{hover ? <ArrowForward /> : <ArrowRight />}
									</ButtonR>
								</BtnWrap>
							</TextWrapper>
						</Column1>
						<Column2>
							<ImgWrap>
								<Img src={img.default} alt={alt} />
							</ImgWrap>
						</Column2>
					</InfoRow>
				</InfoWrapper>
			</InfoContainer>
		</>
	);
};

export default InfoSection;
