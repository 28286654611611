import React from 'react';
import {
	Container,
	Wrapper,
	Heading,
	VideoContainer,
	ColumnLeft,
	Video,
	ColumnRight,
} from './GenericElements';

const GenericVideoContent = ({ content }) => {
	return (
		<>
			<Container lightBg={true}>
				<Heading lightText={false}></Heading>
				<Wrapper>
					<VideoContainer>
						<ColumnLeft>
							<Video
								controls='controls'
								src={content.video}
								autoPlay
								allowFullScreen
								frameBorder='0'
							/>
						</ColumnLeft>
						<ColumnRight>
							<p>{content.description}</p>
							<ul>
								{content.information.map((details, index) => (
									<li key={index}>{details}</li>
								))}
							</ul>
						</ColumnRight>
					</VideoContainer>
				</Wrapper>
				<hr />
			</Container>
		</>
	);
};

export default GenericVideoContent;
