import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SolutionsContainer = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #000b34;
	overflow: hidden;
	text-align: center;

	@media screen and (max-width: 1200px) {
		padding: 100px 0;
		height: max-content;
	}
`;

export const SolutionsWrapper = styled.div`
	max-width: 1600px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	align-items: center;
	grid-gap: 16px;
	padding: 0 50px;

	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 480px) {
		grid-template-columns: 1fr;
		padding: 0 50px;
	}
`;

export const SolutionsCard = styled(Link)`
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-radius: 10px;
	min-height: 280px;
	max-height: 500px;
	padding: 30px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transform: scale(1.02);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
`;

export const SolutionsIcon = styled.img`
	height: 160px;
	width: 160px;
	margin-bottom: 10px;
`;

export const SolutionsH2 = styled.h2`
	font-size: 2.5rem;
	color: #fff;
	margin-bottom: 12px;

	@media screen and (max-width: 480px) {
		font-size: 2rem;
	}
`;

export const SolutionsH3 = styled.div`
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 10px;
	color: #000;
`;

export const SolutionsP = styled.p`
	font-size: 1rem;
	text-align: center;
	color: #000;
`;

export const SolutionsSubHeading = styled.p`
	margin-bottom: 12px;
	font-size: 18px;
	color: #fff;
	margin-bottom: 58px;
	padding: 0 40px;
`;
