export const homeObjOne = {
	id: 'about',
	lightBg: true,
	topLineColor: true,
	lightText: false,
	lightTextDesc: false,
	topLine: 'Who are we?',
	headline: 'About Us',
	descriptionOne:
		"Plotlogic is a global technology company that allows you, the mine operator, to precisely understand the location and material properties of every rock in your mine, in real-time. We have helped some of the world's top mining companies utilise this precision ore-body knowledge to make better decisions, improve ore recovery, reduce dilution and enhance environmental and social governance performance.",
	descriptionTwo: '',
	buttonLabel: 'Learn More',
	imgStart: false,
	img: require('../../images/illustration-1.png'),
	alt: 'About Us Illustration',
	to: '/about',
	dark: false,
	primary: false,
	darkText: true,
};

export const homeObjTwo = {
	id: 'resources',
	lightBg: false,
	topLineColor: false,
	lightText: true,
	lightTextDesc: true,
	topLine: 'Resources',
	headline: 'Read the latest company news, mining insights and case studies',
	descriptionOne: '',
	descriptionTwo: '',
	buttonLabel: 'Read More',
	imgStart: false,
	img: require('../../images/news-insights.png'),
	alt: 'Resources Illustration',
	to: '/resources',
	dark: true,
	primary: true,
	darkText: false,
};
