import React from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import Logo from '../../images/plotlogic-logo.png';
import {
	Nav,
	NavbarContainer,
	NavLogo,
	NavPageIcon,
	MobileIcon,
	NavMenu,
	NavItem,
	NavLinks,
	NavBtn,
	NavBtnLink,
	NavLinkR,
} from './NavbarElements';

const Navbar = ({ toggle }) => {
	const toggleHome = () => {
		scroll.scrollToTop();
	};

	return (
		<>
			<IconContext.Provider value={{ color: '#000' }}>
				<Nav>
					<NavbarContainer>
						<NavLogo to='/' onClick={toggleHome}>
							<NavPageIcon src={Logo} alt='Plotlogic Logo' />
						</NavLogo>
						<MobileIcon onClick={toggle}>
							<FaBars />
						</MobileIcon>
						<NavMenu>
							<NavItem>
								<NavLinks
									to='about'
									smooth={true}
									duration={500}
									spy={true}
									exact='true'
									offset={-80}
								>
									About
								</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinks
									to='solutions'
									smooth={true}
									duration={500}
									spy={true}
									exact='true'
									offset={-80}
								>
									Solutions
								</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinks
									to='resources'
									smooth={true}
									duration={500}
									spy={true}
									exact='true'
									offset={-80}
								>
									Resources
								</NavLinks>
							</NavItem>
							<NavItem>
								<NavLinkR to='/contact' >
									Contact Us
								</NavLinkR>
							</NavItem>							
						</NavMenu>
						<NavBtn>
							<NavBtnLink to='/demo'>Book a Demo</NavBtnLink>
						</NavBtn>
					</NavbarContainer>
				</Nav>
			</IconContext.Provider>
		</>
	);
};

export default Navbar;
