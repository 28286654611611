import React from 'react';
import Icon1 from '../../images/oresense-solution.svg';
import Icon2 from '../../images/otc-solution.svg';
import Icon3 from '../../images/hme-solution.svg';
import Icon4 from '../../images/digital-assay-solution.png';
import Icon5 from '../../images/data-platform-solution.svg';
import {
	SolutionsContainer,
	SolutionsH2,
	SolutionsWrapper,
	SolutionsCard,
	SolutionsIcon,
	SolutionsH3,
	SolutionsSubHeading,
} from './SolutionsElements';

const Solutions = () => {
	return (
		<>
			<SolutionsContainer id='solutions'>
				<SolutionsH2>Our Solutions</SolutionsH2>
				<SolutionsSubHeading>
					Plotlogic's Oresense® technology is available in a variety of
					configurations and can be tailored to meet specific requirements.
				</SolutionsSubHeading>
				<SolutionsWrapper>
					<SolutionsCard to='/solutions/#solution-da'>
						<SolutionsIcon src={Icon4} />
						<SolutionsH3>OreSense® Digital Assay</SolutionsH3>
					</SolutionsCard>
					<SolutionsCard to='/solutions/#solution-ps'>
						<SolutionsIcon src={Icon1} />
						<SolutionsH3>OreSense® Pit Scanning</SolutionsH3>
					</SolutionsCard>
					<SolutionsCard to='/solutions/#solution-otc'>
						<SolutionsIcon src={Icon2} />
						<SolutionsH3>OreSense® Over the Conveyor</SolutionsH3>
					</SolutionsCard>
					<SolutionsCard to='/solutions/#solution-sho'>
						<SolutionsIcon src={Icon3} />
						<SolutionsH3>OreSense® Shovel</SolutionsH3>
					</SolutionsCard>
					<SolutionsCard to='/solutions/#solution-pla'>
						<SolutionsIcon src={Icon5} />
						<SolutionsH3>OreSense® Platforms</SolutionsH3>
					</SolutionsCard>
				</SolutionsWrapper>
			</SolutionsContainer>
		</>
	);
};

export default Solutions;
