import React from 'react';
import { Container, Wrapper, Heading, Content } from './GenericElements';

const GenericText = ({ content }) => {
	return (
		<>
			<Container lightBg={true}>
				<Heading lightText={false}>{content.heading}</Heading>
				<Wrapper>
					<Content lightText={false}>
						{content.description.map((details, index) => (
							<p key={index}>{details}</p>
						))}
					</Content>
				</Wrapper>
			</Container>
		</>
	);
};

export default GenericText;
