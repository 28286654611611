import React from 'react';
import Logo from '../../images/plotlogic-logo.png';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import { Nav, NavbarContainer, NavLogo, NavPageIcon} from './PageNavElements';
import { NavBtn,NavBtnLink } from '../Navbar/NavbarElements';

const PageNav = ({ content }) => {
	const StyledBreadcrumb = withStyles((theme) => ({
		root: {
			backgroundColor: theme.palette.grey[100],
			height: theme.spacing(3),
			color: theme.palette.grey['A400'],
			fontSize:'1rem',
			fontWeight: theme.typography.fontWeightRegular,
			'&:hover, &:focus': {
				backgroundColor: theme.palette.grey[400],
				cursor: 'pointer',
			},
			'&:active': {
				boxShadow: theme.shadows[1],
				backgroundColor: emphasize(theme.palette.grey[400], 0.12),
			},
		},
	}))(Chip);

	return (
		<>
			<Nav>
				<NavbarContainer>
					<NavLogo to='/'>
						<NavPageIcon src={Logo} alt='Plotlogic Logo' />
						<Breadcrumbs aria-label='breadcrumb'>
							<StyledBreadcrumb
								to='/'
								label='Home'
							/* 	icon={<HomeIcon fontSize='small' />} */
								cursor='pointer'
							/>
						{/* 	<StyledBreadcrumb label={content.page} /> */}

						</Breadcrumbs>
					</NavLogo>
				</NavbarContainer>
			</Nav>
		</>
	);
};

export default PageNav;
