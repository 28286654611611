import React from 'react';
import Video from '../../video/hero-video.mp4';
import {
	HeroContainer,
	HeroBg,
	VideoBg,
	HeroContent,
	HeroH1,
	HeroP,
	HeroBtn,
	HeroBtnLink,
} from './HeroVideoElements';

const HeroVideoSection = () => {
	return (
		<>
			<HeroContainer>
				<HeroBg>
					<VideoBg autoPlay loop muted src={Video} type='video/mp4' />
				</HeroBg>
				<HeroContent>
					<HeroH1>Technology for the Future of Natural Resources</HeroH1>
					<HeroBtn>
						<HeroBtnLink to='/demo'>Book a Demo</HeroBtnLink>
					</HeroBtn>
					<HeroP>
						Plotlogic delivers highly accurate ore characterisation in real
						time, enabling greater recovery, reducing waste and enhancing the
						geological model.
					</HeroP>
				</HeroContent>
			</HeroContainer>
		</>
	);
};

export default HeroVideoSection;
