import React, { useState } from 'react';
import {
	ComposableMap,
	Geographies,
	Geography,
	ZoomableGroup,
	Marker,
} from 'react-simple-maps';
import ReactToolTip from 'react-tooltip';
import PageNav from '../../components/PageNav';
import HeroImageSection from '../../components/HeroImageSection';
import { LocationsPageData, LocationMarkers } from './Data';
import { MapContainer } from './LocationsElements';
import { Helmet } from 'react-helmet';

const geoUrl =
	'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json';

const Locations = () => {
	const [content, setContent] = useState('');

	return (
		<>
			<Helmet>
				<title>Plotlogic - System locations</title>
				<meta name='description' content='' />
			</Helmet>
			<PageNav content={LocationsPageData} />
			<HeroImageSection content={LocationsPageData} />
			<MapContainer>
				<ComposableMap
					data-tip=''
					projectionConfig={{ scale: 180 }}
					width={1000}
					height={500}
				>
					<ZoomableGroup zoom={1}>
						<Geographies geography={geoUrl}>
							{({ geographies }) =>
								geographies.map((geo) => (
									<Geography
										key={geo.rsmKey}
										geography={geo}
										fill='#DDD'
										stroke='#FFF'
									/>
								))
							}
						</Geographies>
						{LocationMarkers.map(({ name, coordinates }) => (
							<Marker
								key={name}
								coordinates={coordinates}
								onMouseEnter={() => {
									setContent(name);
								}}
								onMouseLeave={() => {
									setContent('');
								}}
								cursor='pointer'
							>
								<circle r={4} fill='#1d9299' />
							</Marker>
						))}
					</ZoomableGroup>
				</ComposableMap>
				<ReactToolTip>{content}</ReactToolTip>
			</MapContainer>
		</>
	);
};

export default Locations;
