import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import App from '../../App';

var _hsq = (window._hsq = window._hsq || []);

const AnalyticsListener = () => {
	const location = useLocation();

	useEffect(() => {
		sendPageView(location);
	});

	function sendPageView(location) {
		_hsq.push(['setPath', location.pathname]);
		_hsq.push(['trackPageView']);

		ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location.pathname);
	}

	return <App />;
};

export default AnalyticsListener;
