import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Nav = styled.nav`
	background: #f8f9fa;
	height: 80px;
	margin-top: -80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	position: sticky;
	top: 0;
	z-index: 999;

	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`;
export const NavbarContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	height: 80px;
	z-index: 1;
	width: 100%;
	padding: 0 5px;
	max-width: 100%;
`;

export const NavLogo = styled(Link)`
	color: #000;
	justify-self: flex-start;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-left: 24px;
	font-weight: bold;
	text-decoration: none;
`;

export const NavBreadCrumbs = styled(Link)`
	color: #000;
	justify-self: flex-start;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	margin-left: 24px;
	font-weight: bold;
	text-decoration: none;
`;

export const NavLogoWrapper = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	margin: auto;

	@media screen and (max-width: 100px) {
		display: none;
	}
`;

export const NavPageIcon = styled.img`
	max-width: 12.5rem;
	max-height: 12.5rem;
	margin-right: 1rem;

	@media screen and (max-width: 240px) {
		display: none;
	}
`;
