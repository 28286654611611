import React from 'react';
import PageNav from '../../components/PageNav';
import HeroImageSection from '../../components/HeroImageSection';
import NewsSideBar from '../../components/NewsSideBar';
import { NewsPageData, NewsContentData } from './Data';
import { Container } from './NewsElements';
import { Helmet } from 'react-helmet';

const News = () => {
	return (
		<>
			<Helmet>
				<title>Plotlogic - Mining Resources</title>
				<meta name='description' content='' />
			</Helmet>
			<PageNav content={NewsPageData} />
			<HeroImageSection content={NewsPageData} />
			<Container>
				<NewsSideBar content={NewsContentData} />
			</Container>
		</>
	);
};

export default News;
