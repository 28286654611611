import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import NewsContent from '../NewsContent';
import {
	SideBar,
	SideBarWrapper,
	SideBarMenu,
	SideBarTitle,
	SideBarList,
	SideBarListItem,
	LinkedItem,
	ResourcesWrapper,
	ResourcesCard,
	ResourcesIcon,
	ResourcesH2,
} from './NewsSideBarElements';

const NewsSideBar = ({ content }) => {
	const newsTopic = content.find(({ content }) => content === 'News');
	const insightTopic = content.find(({ content }) => content === 'Insight');
	const caseStudyTopic = content.find(
		({ content }) => content === 'Case Study'
	);
	const literatureTopic = content.find(
		({ content }) => content === 'Literature'
	);
	const { url } = useRouteMatch();

	return (
		<>
			<SideBar>
				<SideBarWrapper>
					<SideBarMenu>
						<SideBarTitle style={{ color: '#555' }}>
							{newsTopic.content}
						</SideBarTitle>
						<SideBarList>
							{newsTopic.resources.map((sub) => (
								<SideBarListItem key={sub.id}>
									<LinkedItem
										to={`${url}/${newsTopic.id}/${sub.id}`}
										style={{ color: '#1d9299' }}
									>
										{sub.headline}
									</LinkedItem>
								</SideBarListItem>
							))}
						</SideBarList>
						<SideBarTitle style={{ color: '#555' }}>
							{insightTopic.content}
						</SideBarTitle>
						<SideBarList>
							{insightTopic.resources.map((sub) => (
								<SideBarListItem key={sub.id}>
									<LinkedItem
										to={`${url}/${insightTopic.id}/${sub.id}`}
										style={{ color: '#1d9299' }}
									>
										{sub.headline}
									</LinkedItem>
								</SideBarListItem>
							))}
						</SideBarList>
						<SideBarTitle style={{ color: '#555' }}>
							{caseStudyTopic.content}
						</SideBarTitle>
						<SideBarList>
							{caseStudyTopic.resources.map((sub) => (
								<SideBarListItem key={sub.id}>
									<LinkedItem
										to={`${url}/${caseStudyTopic.id}/${sub.id}`}
										style={{ color: '#1d9299' }}
									>
										{sub.headline}
									</LinkedItem>
								</SideBarListItem>
							))}
						</SideBarList>
						<SideBarTitle style={{ color: '#555' }}>
							{literatureTopic.content}
						</SideBarTitle>
						<SideBarList>
							{literatureTopic.resources.map((sub) => (
								<SideBarListItem key={sub.id}>
									<LinkedItem
										to={`${url}/${literatureTopic.id}/${sub.id}`}
										style={{ color: '#1d9299' }}
									>
										{sub.headline}
									</LinkedItem>
								</SideBarListItem>
							))}
						</SideBarList>
					</SideBarMenu>
				</SideBarWrapper>
			</SideBar>

			<Route path={`${url}/:contentID/:topicID`}>
				<NewsContent data={content} />
			</Route>
			<Route exact path={url}>
				<ResourcesWrapper>
					{newsTopic.resources.map((sub) => (
						<ResourcesCard key={sub.id} to={`${url}/${newsTopic.id}/${sub.id}`}>
							<ResourcesIcon src={sub.image} />
							<ResourcesH2>{sub.headline}</ResourcesH2>
						</ResourcesCard>
					))}
					{insightTopic.resources.map((sub) => (
						<ResourcesCard
							key={sub.id}
							to={`${url}/${insightTopic.id}/${sub.id}`}
						>
							<ResourcesIcon src={sub.image} />
							<ResourcesH2>{sub.headline}</ResourcesH2>
						</ResourcesCard>
					))}
					{caseStudyTopic.resources.map((sub) => (
						<ResourcesCard
							key={sub.id}
							to={`${url}/${caseStudyTopic.id}/${sub.id}`}
						>
							<ResourcesIcon src={sub.image} />
							<ResourcesH2>{sub.headline}</ResourcesH2>
						</ResourcesCard>
					))}
					{literatureTopic.resources.map((sub) => (
						<ResourcesCard
							key={sub.id}
							to={`${url}/${literatureTopic.id}/${sub.id}`}
						>
							<ResourcesIcon src={sub.image} />
							<ResourcesH2>{sub.headline}</ResourcesH2>
						</ResourcesCard>
					))}
				</ResourcesWrapper>
			</Route>
		</>
	);
};

export default NewsSideBar;
