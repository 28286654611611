import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import AnalyticsListener from './components/AnalyticsListener';
import ScrollHandler from './components/ScrollHandler';

//const G4_TRACKING_ID = 'G-4HHLC0H6C9';
const UA_TRACKING_ID = 'UA-225472488-1';
ReactGA.initialize(UA_TRACKING_ID);

ReactDOM.render(
	<Router>
		<ScrollToTop />
		<ScrollHandler />
		<AnalyticsListener></AnalyticsListener>
	</Router>,
	document.getElementById('root')
);
