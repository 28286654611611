import React from 'react';
import GenericVideoContent from '../../components/GenericVideoSection';
import HeroImageSection from '../../components/HeroImageSection';
import PageContents from '../../components/PageContent';
import PageNav from '../../components/PageNav';
import { ServicesPageData, ContentData, VideoContentData } from './Data';
import { Helmet } from 'react-helmet';

const Solutions = () => {
	return (
		<>
			<Helmet>
				<title>Plotlogic - Improve environmental mining</title>
				<meta name='description' content='' />
			</Helmet>
			<PageNav content={ServicesPageData} />
			<HeroImageSection content={ServicesPageData} />
			<GenericVideoContent content={VideoContentData} />
			<PageContents content={ContentData} />
		</>
	);
};

export default Solutions;
