import React from 'react';
import { Container, Content, Wrapper, Image } from './ImageDisplayElements';

const ImageDisplay = ({ content }) => {
	return (
		<>
			<Container id={`#investors`}>
				<Content>
					<h2>{content.title}</h2>
					<p>{content.excerpt}</p>
				</Content>
				<Wrapper>
					{content.resources.map((images) => (
						<Image key={images.id} src={images.logo} alt={images.alt} />
					))}
				</Wrapper>
			</Container>
		</>
	);
};

export default ImageDisplay;
