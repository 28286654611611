import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import Sidebar from '../../components/Sidebar';
import HeroVideoSection from '../../components/HeroVideoSection';
import InfoSection from '../../components/InfoSection';
import { homeObjOne, homeObjTwo } from './Data';
import Solutions from '../../components/Solutions';
import { Helmet } from 'react-helmet';

const Home = () => {
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<>
			<Helmet>
				<title>Plotlogic - Mining Technology</title>
				<meta name='description' content='' />
			</Helmet>
			<Sidebar isOpen={isOpen} toggle={toggle} />
			<Navbar toggle={toggle} />
			<HeroVideoSection />
			<InfoSection {...homeObjOne} />
			<Solutions />
			<InfoSection {...homeObjTwo} />
		</>
	);
};

export default Home;
