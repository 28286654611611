import styled from 'styled-components';

export const Container = styled.div`
	background: ${({ lightBg }) => (lightBg ? '#fff' : '#000b34')};
	min-height: 25vh;
	padding: 2rem 2rem;
	position: relative;
	overflow: auto;

	@media screen and (max-width: 120vh) {
		height: max-content;
		padding-top: 50px;
		padding-bottom: 50px;
	}
`;

export const Wrapper = styled.div`
	width: 100%;
	max-width: 80vw;
	margin: 0 auto;
`;

export const Content = styled.div`
	flex: 0 0 50%;
	text-align: center;
	color: ${({ lightText }) => (lightText ? '#fff' : '#010606')};

	@media screen and (max-width: 768px) {
		flex: 0 0 100%;
		max-width: 100%;
	}

	h1 {
		margin-bottom: 2rem;
		font-size: 2rem;
	}

	p {
		margin-bottom: 1rem;
		line-height: 1.5;
	}
`;

export const Heading = styled.h2`
	margin-top: 24px;
	margin-bottom: 24px;
	line-height: 1.1;
	font-weight: 600;
	text-align: center;
	color: ${({ lightText }) => (lightText ? '#fff' : '#010606')};
	font-size: 2rem;
`;
