import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
	Headline,
	HeadlineImage,
	NewsContentContainer,
	Description,
	DescriptionWrapper,
	LinkContainer,
	FurtherInformationLink,
	Video,
	VideoContainer,
	PDF,
	PDFContainer,
	ImageRow,
	ImageColumn,
} from './NewsContentElements';

const NewsContent = ({ data }) => {
	const { contentID, topicID } = useParams();

	const topic = data
		.find(({ id }) => id === contentID)
		.resources.find(({ id }) => id === topicID);

	function VideoContent() {
		return (
			<VideoContainer>
				<Video
					controls='controls'
					src={topic.youtube}
					frameBorder='0'
					allowFullScreen
					autoPlay
				/>
			</VideoContainer>
		);
	}

	function PDFContent() {
		return (
			<PDFContainer>
				<PDF src={topic.pdf} frameBorder='0' />
			</PDFContainer>
		);
	}

	function Content() {
		return (
			<>
				<DescriptionWrapper>
					{topic.description.map((paragraphs, index) => (
						<Description key={index}>{paragraphs}</Description>
					))}
				</DescriptionWrapper>
			</>
		);
	}

	function ExternalSpecificLink() {
		return (
			<LinkContainer>
				<p>
					<b>{topic.specificLinks.title}</b>
				</p>
				{topic.specificLinks.links.map((links, index) => (
					<p key={index} style={{ marginTop: '10px' }}>
						{links.miniTitle}{' '}
						<FurtherInformationLink href={links.link} target='_blank'>
							Click Here
						</FurtherInformationLink>
					</p>
				))}
			</LinkContainer>
		);
	}

	function ExternalLink() {
		return (
			<FurtherInformationLink href={topic.link} target='_blank'>
				{topic.link.title}
			</FurtherInformationLink>
		);
	}

	function Articles() {
		return (
			<>
				{topic.articles.map((literature) => (
					<>
						<h3 style={{ marginTop: '10px', marginBottom: '10px' }}>
							{literature.title}
						</h3>
						<p style={{ marginBottom: '10px' }}>
							{literature.reference}
							<i>{literature.referenceItalicized}</i>
						</p>
						<p>
							<b>{literature.descriptionTitle}</b>
						</p>
						{literature.abstract.map((paragraphs, index) => (
							<p key={index} style={{ marginBottom: '10px' }}>
								{paragraphs}
							</p>
						))}
						<p style={{ marginBottom: '15px' }}>
							<b>For the full article - </b>
							<FurtherInformationLink href={literature.link} target='_blank'>
								click here
							</FurtherInformationLink>
						</p>
						<ImageRow>
							{literature.authors.map((author, index) => (
								<ImageColumn key={{ index }}>
									<Link to={`/about/#person${author.id}`}>
										<img
											src={author.image}
											alt={author.name}
											style={{ width: '100px', height: '100px' }}
										></img>
									</Link>
									<p style={{ marginBottom: '5px', textAlign: 'center' }}>
										{author.name}
									</p>
								</ImageColumn>
							))}
						</ImageRow>
						<hr style={{ color: '#D3D3D3' }} />
					</>
				))}
			</>
		);
	}

	function CaseStudy() {
		return (
			<>
				{topic.caseContent.map((content) => (
					<>
						<h3
							style={{
								marginTop: '10px',
								marginBottom: '10px',
								color: '#1d9299',
							}}
						>
							{content.subtitle}
						</h3>
						{content.text.map((paragraphs, index) => (
							<p key={index} style={{ marginBottom: '10px' }}>
								{paragraphs}
							</p>
						))}
						{content.subcontent.map((body, index) => (
							<>
								<h4
									key={index}
									style={{ marginBottom: '10px', color: '#6f8dad' }}
								>
									{body.miniheading}
								</h4>
								<p style={{ marginBottom: '10px' }}>{body.subtext}</p>
							</>
						))}
					</>
				))}
				<p style={{ marginBottom: '10px' }}>
					<a href={topic.download} download>
						Please click here to download the full case study.
					</a>
				</p>
			</>
		);
	}

	return (
		<>
			{topic.hasOwnProperty('titleSEO') ? (
				<Helmet>
					<title>{topic.titleSEO}</title>
					<meta name='description' content='' />
				</Helmet>
			) : (
				<Helmet>
					<title>Plotlogic - Mining Resources</title>
					<meta name='description' content='' />
				</Helmet>
			)}
			<NewsContentContainer>
				<HeadlineImage src={topic.image} alt={topic.alt} />
				<Headline>{topic.headline}</Headline>
				<br />
				<hr style={{ color: '#555' }} />
				{topic.hasOwnProperty('articles') ? <Articles /> : ''}
				{topic.description ? <Content /> : ''}
				{topic.hasOwnProperty('caseContent') ? <CaseStudy /> : ''}
				{topic.hasOwnProperty('specificLinks') ? <ExternalSpecificLink /> : ''}
				{topic.hasOwnProperty('link') ? <ExternalLink /> : ''}
				{topic.hasOwnProperty('youtube') ? <VideoContent /> : ''}
				{topic.hasOwnProperty('pdf') ? <PDFContent /> : ''}
			</NewsContentContainer>
		</>
	);
};

export default NewsContent;
