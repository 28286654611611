import React from 'react';
import {
	Section,
	Container,
	ColumnLeft,
	ColumnRight,
} from './PageContentElements';

const PageContents = ({ content }) => {
	return (
		<>
			{content.map((details, index) => (
				<Section key={index} id={`#solution-${details.id}`}>
					<Container>
						<ColumnLeft
							reverse={details.reverse}
							data-aos='fade-up'
							data-aos-duration='2000'
							data-aos-once='true'
							data-aos-delay={details.delay}
							data-aos-anchor-placement='bottom center'
						>
							<h2>{details.heading}</h2>
							{details.paragraphs.map((items, index) => (
								<p key={index}>{items}</p>
							))}

							<h3>Benefits</h3>
							<ul>
								{details.benefits.map((items, index) => (
									<li key={index}>{items}</li>
								))}
							</ul>
						</ColumnLeft>
						<ColumnRight reverse={details.reverse}>
							<img
								src={details.image}
								alt={details.alt}
								data-aos='zoom-out'
								data-aos-duration='2000'
								data-aos-once='true'
								data-aos-delay={details.delay}
								data-aos-anchor-placement='bottom center'
							/>
						</ColumnRight>
					</Container>
				</Section>
			))}
		</>
	);
};

export default PageContents;
