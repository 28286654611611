import styled from 'styled-components';

export const NewsContentContainer = styled.div`
	flex: 4;
	padding: 20px;
	margin-bottom: 20px;
	overflow: auto;

	@media screen and (max-width: 480px) {
		flex: 2;
	}
`;

export const HeadlineImage = styled.img`
	width: 100%;
	height: 24vh;
	object-fit: cover;

	@media screen and (max-width: 1200px) {
		height: max-content;
		object-fit: contain;
	}

	@media screen and (max-width: 1024px) {
		height: 14vh;
	}

	@media screen and (max-width: 768px) {
		height: 10vh;
	}	
`;

export const Headline = styled.h2`
	padding-top: 10px;
`;

export const DescriptionWrapper = styled.div`
	padding-top: 20px;
	padding-bottom: 10px;
`;

export const Description = styled.p`
	padding-bottom: 10px;
`;

export const LinkContainer = styled.div`
	margin-bottom: 10px;
`;

export const FurtherInformationLink = styled.a`
	margin-top: 30px;
`;

export const VideoContainer = styled.div`
	display: flex;
	padding-top: 10px;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	overflow: auto;
	height: 550px;

	@media screen and (max-width: 480px) {
		height: auto;
	}
`;

export const Video = styled.iframe`
	width: 100%;
	height: 100%;
`;

export const PDFContainer = styled.div`
	display: flex;
	padding-top: 10px;
	padding-bottom: 10px;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	overflow: auto;
	width: 100%;
	flex-direction: column;
`;

export const PDF = styled.iframe`
	width: 100%;
	height: 1100px;
`;

export const ImageRow = styled.div`
	display: flex;
`;

export const ImageColumn = styled.div`
	width: '100px';
	height: '100px';
	float: 'left';
`;
