import React from 'react';
import PageNav from '../../components/PageNav';
import HeroImageSection from '../../components/HeroImageSection';
import HubEmbed from '../../components/HubEmbed/HubEmbed'
import { demoPageData } from './Data';
import { Helmet } from 'react-helmet';

const Demo = () => {
	return (
		<>
			<Helmet>
				<title>Plotlogic - Book a Demo</title>
				<meta name='description' content='' />
			</Helmet>
			<PageNav content={demoPageData} />
			<HeroImageSection content={demoPageData} />			
			<HubEmbed 
				title='Book an online demo to see how Plotlogic can give you the tools to precisely understand the location and material properties of every rock in your mine, in real-time.'
				embedId="1MwcjL1J8TgeKsgb6TSTVcwbwero"
			/>
			
		</>
	);
};

export default Demo;
