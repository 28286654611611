import React from "react";
import PropTypes from "prop-types";

const HubEmbed = ({ embedId, title }) => (
  <div className="pageMed">
    <div className="colLeft">
        <h2>
        {title}
        </h2>
    </div>
    <div className="colRight">
        <div className="hub-responsive">
            <iframe
            width="100%"
            height="100%"
            src={`https://share.hsforms.com/${embedId}`}
            frameBorder="0"      
            allowFullScreen
            title="Embedded Form"
            />
        </div>
    </div>
  </div>
);

HubEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default HubEmbed;