import React from 'react';
import {
	HeroContainer,
	HeroBg,
	ImageBg,
	HeroContent,
	HeroH1,
} from './HeroImageElements';

const HeroImageSection = ({ content }) => {
	return (
		<>
			<HeroContainer>
				<HeroBg>
					<ImageBg src={content.image} alt={content.alt} />
				</HeroBg>
				<HeroContent>
					<HeroH1>{content.heroHeader}</HeroH1>
				</HeroContent>
			</HeroContainer>
		</>
	);
};

export default HeroImageSection;
