import React from 'react';
import HeroImageSection from '../../components/HeroImageSection';
import PageNav from '../../components/PageNav';
import {
	AboutPageData,
	IntroData,
	PeopleData,
	InvestorsData,
	AdvisorData,
	ExperienceData,
} from './Data';
import PeopleSection from '../../components/PeopleSection';
import GenericText from '../../components/GenericTextSection';
import Features from '../../components/FeaturesSection';
import AdvisoryBoard from '../../components/AdvisoryBoardSection';
import ImageDisplay from '../../components/ImageDisplaySection';
import { Helmet } from 'react-helmet';

export default function About() {
	return (
		<>
			<Helmet>
				<title>Plotlogic - Real time ore characterisation</title>
				<meta name='description' content='' />
			</Helmet>
			<PageNav content={AboutPageData} />
			<HeroImageSection content={AboutPageData} />
			<GenericText content={IntroData} />
			<AdvisoryBoard content={AdvisorData} />
			{/* <PeopleSection content={PeopleData} /> */}
			<Features content={ExperienceData} />
			<ImageDisplay content={InvestorsData} />
		</>
	);
}
