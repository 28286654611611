import React from 'react';
import {
	PeopleContainer,
	PeopleH2,
	PeopleWrapper,
	PeopleCard,
	PeopleIcon,
	PeopleH3,
	PeopleP,
} from './AdvisoryBoardElements';

const AdvisoryBoard = ({ content }) => {
	return (
		<>
			<PeopleContainer id='Advisory'>
				<PeopleH2>Board & Advisory</PeopleH2>
				<PeopleWrapper>
					{content.map((details) => (
						<PeopleCard key={details.id} id={`#person${details.id}`}>
							<PeopleIcon src={details.icon} alt={details.name} />
							<PeopleH3>{details.name}</PeopleH3>
							<PeopleH3>{details.title}</PeopleH3>
							<PeopleP>{details.about}</PeopleP>
						</PeopleCard>
					))}
				</PeopleWrapper>
			</PeopleContainer>
		</>
	);
};

export default AdvisoryBoard;
