import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { FaLinkedin, FaFacebook, FaInstagram, FaVimeo } from 'react-icons/fa';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Button } from '../ButtonElement';
import Logo from '../../images/plotlogic-logo-inverted.png';
import {
	FooterContainer,
	FooterSubscription,
	FooterSubHeading,
	FooterSubText,
	FooterIcon,
	Form,
	FormInput,
	FooterWrap,
	FooterLinksContainer,
	FooterLinksWrapper,
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	SocialMedia,
	SocialMediaWrap,
	SocialLogo,
	WebsiteRights,
	SocialIcons,
	SocialIconLink,
} from './FooterElements';

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const Footer = () => {
	const scrollToTop = () => {
		scroll.scrollToTop();
	};

	const [email, setEmail] = useState('');
	const [open, setOpen] = useState(false);
	const [severity, setSeverity] = useState('');
	const [message, setMessage] = useState('');

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const handleSubmit = (evt) => {
		evt.preventDefault();
		var xhr = new XMLHttpRequest();
		var url =
			'https://api.hsforms.com/submissions/v3/integration/submit/19987908/c0501876-bff7-40d1-9cc8-fc01d9779470';
		var data = {
			fields: [
				{
					name: 'email',
					value: email,
				},
			],
		};

		var final_data = JSON.stringify(data);

		xhr.open('POST', url);
		xhr.setRequestHeader('Content-Type', 'application/json');

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				setSeverity('success');
				setMessage('Thanks for subscribing!');
				setOpen(true);
			} else if (xhr.readyState === 4 && xhr.status === 400) {
				setSeverity('error');
				setMessage('Please provide a valid email.');
				setOpen(true);
			} else if (xhr.readyState === 4 && xhr.status === 403) {
				setSeverity('warning');
				setMessage('Oops... Something went wrong. Please try again.');
				setOpen(true);
			} else if (xhr.readyState === 4 && xhr.status === 404) {
				setSeverity('error');
				setMessage("Subscription to Plotlogic's newsletter no longer exists.");
				setOpen(true);
			}
		};

		// Sends the request

		xhr.send(final_data);
	};

	return (
		<>
			<FooterContainer>
				<FooterSubscription>
					<FooterSubHeading>
						Enter your email to receive the latest Plotlogic news and updates
					</FooterSubHeading>
					<FooterSubText>You can unsubscribe at any time.</FooterSubText>
					<Form onSubmit={handleSubmit}>
						<FormInput
							name='email'
							type='email'
							value={email}
							placeholder='Your Email'
							onChange={(e) => setEmail(e.target.value)}
						/>
						<Button type='submit'>Subscribe</Button>
					</Form>
				</FooterSubscription>

				<FooterWrap>
					<FooterLinksContainer>
						<FooterLinksWrapper>
							<FooterLinkItems>
								<FooterLinkTitle>About Us</FooterLinkTitle>
								<FooterLink to='/about'>Who is Plotlogic</FooterLink>
								<FooterLink to='/about/#investors'>Investors</FooterLink>
								<FooterLink to='/privacy-policy'>Privacy Policy</FooterLink>
							</FooterLinkItems>
							<FooterLinkItems>
								<FooterLinkTitle>Contact Us</FooterLinkTitle>
								<FooterLink to='/contact'>Contact Us</FooterLink>
							</FooterLinkItems>
						</FooterLinksWrapper>
					</FooterLinksContainer>

					<SocialMedia>
						<SocialMediaWrap>
							<SocialLogo to='/' onClick={scrollToTop}>
								<FooterIcon src={Logo} alt='Plotlogic Logo' />
							</SocialLogo>
							<WebsiteRights>
								&copy; {new Date().getFullYear()} Plotlogic Pty Ltd ABN:
								31115988308 All rights reserved.
								<br />
								Central 1 – A1 – 605 Zillmere Road Zillmere QLD 4034
								<br />
								E: <a href="mailto:info@plotlogic.com">info@plotlogic.com</a>
							</WebsiteRights>
							<SocialIcons>
								<SocialIconLink
									href='//www.linkedin.com/company/plotlogic'
									target='_blank'
									aria-label='LinkedIn'
								>
									<FaLinkedin />
								</SocialIconLink>
								<SocialIconLink
									href='//www.facebook.com/Plotlogic-100198342338210'
									target='_blank'
									aria-label='Facebook'
								>
									<FaFacebook />
								</SocialIconLink>
								<SocialIconLink
									href='//www.instagram.com/plotlogic'
									target='_blank'
									aria-label='Instagram'
								>
									<FaInstagram />
								</SocialIconLink>
								<SocialIconLink
									href='//vimeo.com/user76350332'
									target='_blank'
									aria-label='Vimeo'
								>
									<FaVimeo />
								</SocialIconLink>
							</SocialIcons>
						</SocialMediaWrap>
					</SocialMedia>

					<Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
						<Alert onClose={handleClose} severity={severity}>
							{message}
						</Alert>
					</Snackbar>
				</FooterWrap>
			</FooterContainer>
		</>
	);
};

export default Footer;
