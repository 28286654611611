import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NotFoundContainer = styled.div`
	color: #333;
	text-align: center;
	height: 62.5vh;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 30px;
	position: relative;
`;

export const NotFoundContent = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	p {
		padding-top: 10px;
	}
`;

export const ReturnHomeLink = styled(Link)`
	color: #4979ff;
	text-decoration: underline;
`;
