import HeroImage from '../../images/hero-image-9.png';
import LVConfiguration from '../../images/lv-configuration.png';
import OTCConfiguration from '../../images/otc-configuration.png';
import HMEConfiguration from '../../images/hme-configuration.png';
import DataPlatformSolutions from '../../images/data-platform-solutions.png';
import DigitalAssaySolution from '../../images/digital-assay-solutions.png';

export const ServicesPageData = {
	page: 'Solutions',
	heroHeader: 'Solutions',
	image: HeroImage,
	alt: 'Hero Banner',
};

export const VideoContentData = {
	video: 'https://player.vimeo.com/video/584667445',
	description:
		"The technology underpinning Plotlogic systems is founded on the principles of hyperspectral imaging. Plotlogic's OreSense® technology fuses LiDAR and Hyperspectral data in real time and uses advanced algorithms to deliver centimetre resolution grade control and mineral mapping. The OreSense® system:",
	information: [
		'Delivers direct cost savings, increases profit margin, reduces exposure and improves corporate social responsibility performance. ',
		'Directly integrates into your daily scheduling workflows, as well as automatically interfaces with your geological, geotechnical and mine rehabilitation models.',
		'Delivers accurate data to inform decisions and improve short- and medium-term operational planning.',
		'Enables mines to perform accurate grade modelling, mineral mapping, and environmental and geotechnical assessments - from anywhere in the world. ',
		'Delivers high precision mine mapping and accurate bucket-by-bucket ore grade determination in real-time - no chemical assay, no physical contact.',
	],
};

export const ContentData = [
	{
		id: 'da',
		heading: 'OreSense® Digital Assay',
		paragraphs: [
			'OreSense® Digital Assay is a hyperspectral assaying system which is used for grade control and real time mineralogical classification. This configuration is easily integrated into current grade control workflows vastly improving turn-around time, reducing assaying costs, and enabling greater sampling therefore resulting in improved representation. In addition to grade abundances, data on mineralogy, deleterious materials and moisture is also collected providing valuable insight to improve operations, reduce greenhouse gas emissions and increase profitability.',
		],
		image: DigitalAssaySolution,
		alt: 'Digital Assay',
		benefits: [
			'Flexible system set up allows use on-site at the bench.',
			'Direct detection of materials, with the data generated useable for geology modelling, blast designing, mine scheduling and informing materials fed to mill.',
			'Automated quality assurance backed by expert geologists.',
			'Samples are processed in < 3 minutes with results immediately useable.',
		],
		reverse: false,
		delay: 600,
	},
	{
		id: 'ps',
		heading: 'OreSense® Pit Scanning',
		paragraphs: [
			"The pit scanning configuration provides flexible uses with options to scan in pit, at stockpiles and highwalls. OreSense® Pit Scanning is light weight and can be easily transported on a vehicle. Scanning is performed semi-autonomously, is non-invasive and doesn't require contact with the mining face.",
		],
		image: LVConfiguration,
		alt: 'Light Vehicle Configuration',
		benefits: [
			'Real time identification of mineralogy ore grade, waste and deleterious materials in pit or at stockpiles.',
			'Improve mine scheduling and processing decisions by assessing accurate data regarding material being mined or stockpiled.',
			'Rapid integration into modelling workflows enabling faster decision making.',
			'Complementary to existing survey workflows.',
		],
		reverse: true,
		delay: 600,
	},
	{
		id: 'otc',
		heading: 'OreSense® Over the Conveyor',
		paragraphs: [
			'The OreSense® system can be mounted above the conveyor belt for accurate, real-time material characterisation without the the need to run complex system calibrations and maintenance.',
			'The OreSense® system scans the ore as it travels along the conveyor, identifying deleterious materials or variance in ore quality in real time. The data delivered identifies mineralogy, feed grade and deleterious materials, as well as accurate volume and tonnes',
		],
		image: OTCConfiguration,
		alt: 'Over The Conveyor Configuration',
		benefits: [
			'Optimise plant throughput and performance.',
			'Improve health and safety by reducing plant stoppages and the need for manual repairs.',
			'Provide real time information to assist in optimising plant performance (e.g. conveyor speed).',
		],
		reverse: false,
		delay: 600,
	},
	{
		id: 'sho',
		heading: 'OreSense® Shovel',
		paragraphs: [
			'Integrated into a dig fleet, OreSense® Shovel produces material characterisation of the entire ore face during digging.',
		],
		image: HMEConfiguration,
		alt: 'HME Configuration',
		benefits: [
			'Scan the active ore face in real time, bucket by bucket.',
			'Allocate ore and waste material to suitable stockpiles and reduce re-handling.',
			'Improve your mine to mill processes, starting with the initial digging followed by material movement.',
			'Make informed decisions on the ore-waste boundary and deleterious materials in pit reducing dilution.',
		],
		reverse: true,
		delay: 600,
	},
	// {
	// 	id: 'bh',
	// 	heading: 'OreSense® Blast Hole',
	// 	paragraphs: [
	// 		'OreSense® Blast Hole is a hyperspectral blast hole logging tool, that integrates point-spectroradiometry with LiDAR, GNSS and high resolution RGB photogrammetry. Combined with Plotlogic’s proprietary algorithms it provides centimetre level precision of hole depth, dip, deviation, volume, cavities, mineral properties and grade prediction.',
	// 	],
	// 	image: OreProbeConfiguration,
	// 	alt: 'Oresense Blast Hole Configuration',
	// 	benefits: [
	// 		'Detection of cavities, hole depth, water level and precise hole position for fragmentation optimisation.',
	// 		'Ore and waste mineralogy classification.',
	// 		'Ore grade characterisation for geological modelling and scheduling.',
	// 		'Fast turnarounds to support short-term planning optimisation.',
	// 		'In-pit real-time logging with automatic processing and reporting.',
	// 		'Scanning completed in minutes (depth dependent).',
	// 		'Make informed scheduling decisions. ',
	// 	],
	// 	reverse: false,
	// 	delay: 600,
	// },
	{
		id: 'pla',
		heading: 'OreSense® Platforms',
		paragraphs: [
			"OreSense® Platforms is Plotlogic's collection of algorithms, API's and visualisation tools that interface with your geological models and end users.",
			'OreSense® Platforms allows mine planners, supervisors, and geologists to select how data is best integrated within their workflows. Newly obtained information captured by OreSense® systems across the value chain can be used to update relevant parts of the block model or process flow. Alternatively, our APIs allow integration with your existing short-term scheduling tools to provide greater transparency over process variations as they occur.',
		],
		image: DataPlatformSolutions,
		alt: 'Platforms',
		benefits: [
			'Compare expected ore characteristics with those measured by OreSense®.',
			'Data captured and analysed in real time used to inform your decision support system (DSS).',
			'Improved monitoring of mining process through actionable reports generated by Platforms.',
		],
		reverse: false,
		delay: 600,
	},
];
