import React from 'react';
import Navbar from '../Navbar';
import {
	NotFoundContainer,
	NotFoundContent,
	ReturnHomeLink,
} from './NotFoundElements';

const NotFound = () => {
	return (
		<>
			<Navbar />
			<NotFoundContainer>
				<NotFoundContent>
					<h1>Ooops...</h1>
					<h2>Something happened!</h2>
					<p>
						Go back to the <ReturnHomeLink to='/'>Homepage</ReturnHomeLink>
					</p>
				</NotFoundContent>
			</NotFoundContainer>
		</>
	);
};

export default NotFound;
