import styled from 'styled-components';

export const PeopleContainer = styled.div`
	min-height: 70vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #cbdee7;
	overflow: auto;
	padding-bottom: 50px;

	@media screen and (max-width: 120vh) {
		padding-top: 50px;
		padding-bottom: 50px;
		height: max-content;
	}
`;

export const PeopleWrapper = styled.div`
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	grid-gap: 16px;
	padding: 0 50px;

	@media screen and (max-width: 1500px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr;
		padding: 0 20px;
	}

	@media screen and (max-width: 1000px) {
		grid-template-columns: 1fr;
	}
`;

export const PeopleCard = styled.div`
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-radius: 10px;
	min-height: 560px;
	max-height: 800px;
	padding: 30px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transform: scale(1.02);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
`;

export const PeopleIcon = styled.img`
	height: 240px;
	width: 240px;
	margin-bottom: 10px;
`;

export const PeopleH2 = styled.h2`
	font-size: 2rem;
	color: #000b34;
	margin-bottom: 64px;
	margin-top: 50px;
	text-align: center;

	@media screen and (max-width: 480px) {
		font-size: 2rem;
	}
`;

export const PeopleH3 = styled.div`
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 10px;
	color: #000;
	text-align: center;
`;

export const PeopleP = styled.p`
	font-size: 1rem;
	text-align: center;
	color: #000;
`;
