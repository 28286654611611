import './App.css';
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Demo from './pages/Demo/Demo';
import News from './pages/News/News';
import Solutions from './pages/Solutions/Solutions';
import Privacy from './pages/Privacy/Privacy';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Locations from './pages/Locations/Locations';

export default function App() {
	useEffect(() => {
		Aos.init({});
	}, []);

	return (
		<>
			<Switch>
				<Route exact path='/' component={Home} />
				<Route path='/about' component={About} />
				<Route path='/contact' component={Contact} />
				<Route path='/demo' component={Demo} />
				<Route path='/solutions' component={Solutions} />
				<Route path='/resources' component={News} />
				<Route path='/locations' component={Locations} />
				<Route path='/privacy-policy' component={Privacy} />
				<Route path='*' component={NotFound} />
			</Switch>
			<Footer />
		</>
	);
}
