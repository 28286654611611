import styled from 'styled-components';

export const PeopleContainer = styled.div`
	min-height: 560px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #000b34;
	overflow: auto;
	padding-bottom: 50px;

	@media screen and (max-width: 120vh) {
		padding-top: 50px;
		padding-bottom: 50px;
		height: max-content;
	}
`;

export const DivisionWrapper = styled.div`
	height: max-content;
	padding-bottom: 10px;
`;

export const PeopleWrapper = styled.div`
	max-width: 100%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	align-items: center;
	grid-gap: 12px;
	padding: 0 20px;

	@media screen and (max-width: 170vh) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		padding: 0 20px;
	}

	@media screen and (max-width: 140vh) {
		grid-template-columns: 1fr 1fr 1fr;
		padding: 0 20px;
	}

	@media screen and (max-width: 110vh) {
		grid-template-columns: 1fr 1fr;
		padding: 0 20px;
	}

	@media screen and (max-width: 75vh) {
		grid-template-columns: 1fr;
		padding: 0 20px;
	}
`;

export const PeopleCard = styled.button`
	background: #fff;
	border: none;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-radius: 10px;
	height: 100%;
	max-height: 600px;
	padding: 20px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transform: scale(1.02);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
`;

export const PeopleIcon = styled.img`
	height: 200px;
	width: 200px;
	margin-bottom: 10px;
`;

export const ModalIcon = styled.img`
	display: block;
	margin-left: auto;
	margin-right: auto;
	height: 300px;
	width: 300px;
	margin-bottom: 10px;
`;

export const PeopleH2 = styled.h2`
	font-size: 2rem;
	color: #fff;
	margin-bottom: 50px;
	margin-top: 50px;
	text-align: center;

	@media screen and (max-width: 480px) {
		font-size: 2rem;
	}
`;

export const PeopleH3 = styled.div`
	font-size: 1rem;
	font-weight: bold;
	margin-bottom: 10px;
	color: #000;
	text-align: center;	
`;

export const PeopleFL = styled.div`
	font-size: 1rem;
	font-weight: bold;
	color: #000;
	float: left;
	display: inline;
	margin-bottom: 10px;
`;

export const PeopleP = styled.p`
	font-size: 1rem;
	text-align: center;
	color: #000;
`;

export const DivisionP = styled.p`
	font-size: 1rem;
	text-align: left;
	color: #fff;
	padding-bottom: 10px;
	padding-left: 15px;
	font-weight: bold;
`;

export const SocialIcons = styled.div`
	float: right;
    padding-left: 10px;
	margin-top: -6px;
`;

export const SocialIconLink = styled.a`
	color: #2867b2;
	font-size: 24px;
`;
