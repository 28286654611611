import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SideBar = styled.div`
	flex: 1;
	overflow: auto;
	background-color: rgb(251, 251, 255);

	@media screen and (max-width: 768px) {
		flex: 0;
	}
`;

export const SideBarWrapper = styled.div``;

export const SideBarMenu = styled.div``;

export const SideBarTitle = styled.h3`
	color: '#555';
	padding: 20px;
`;

export const SideBarList = styled.ul`
	list-style-type: none;
	padding-left: 20px;
`;

export const SideBarListItem = styled.li`
	margin-bottom: 5px;
`;

export const LinkedItem = styled(Link)`
	text-decoration: none;
	color: '#1d9299';
`;

export const ResourcesWrapper = styled.div`
	flex: 4;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	align-items: center;
	grid-gap: 16px;
	padding: 10px 20px;
	overflow: auto;

	@media screen and (max-width: 1200px) {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 480px) {
		grid-template-columns: 1fr;
		padding: 0 50px;
	}
`;

export const ResourcesCard = styled(Link)`
	background: #fff;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	border-radius: 10px;
	min-height: 200px;
	max-height: 300px;
	padding: 10px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		transform: scale(1.02);
		transition: all 0.2s ease-in-out;
		cursor: pointer;
	}
`;

export const ResourcesIcon = styled.img`
	height: 100px;
	width: 100%;
	object-fit: scale-down;
	margin-bottom: 10px;
`;

export const ResourcesH2 = styled.div`
	font-size: 1rem;
	font-weight: bold;
	text-align: center;
	margin-bottom: 10px;
	color: #000;
`;
